import { LogService } from './log.service';
import { LogLevel } from './LogLevel';

export class LogServiceHolder {
  static instance: LogService | null = null;

  static setLogger(logger: LogService) {
    this.instance = logger;
  }

  static getLogger(): LogService | null {
    return this.instance;
  }

  static log(message: string, level: LogLevel, source: string) {
    this.instance?.log(message, level, source);
  }
}
