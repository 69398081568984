import { LogLevel as MsalLogLevel } from '@azure/msal-browser';

export class LogLevel {
  static readonly None    = 0;
  static readonly Debug   = 1;
  static readonly Info    = 2;
  static readonly Warning = 3;
  static readonly Error   = 4;
  static readonly Breach  = 5;
  static readonly Ban     = 6;

  static readonly IsMsal  = 100;

  static parseMsal(level: MsalLogLevel): number {
    switch (level) {
      case MsalLogLevel.Verbose: return LogLevel.Debug + LogLevel.IsMsal;
      case MsalLogLevel.Info:    return LogLevel.Info  + LogLevel.IsMsal;
      case MsalLogLevel.Warning: return LogLevel.Warning + LogLevel.IsMsal;
      case MsalLogLevel.Error:   return LogLevel.Error + LogLevel.IsMsal;
      default: return LogLevel.None;
    }
  }
}
