import { Injectable } from '@angular/core';
import { LogLevel } from './LogLevel';
import { DateUtils } from 'src/app/global.extensions';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {}

  public async log(message: string, level: LogLevel, sourceComponent: string): Promise<void> {
    
    if (!message?.trim() || !sourceComponent?.trim()) return;

    try {
      let finalMessage = message;
      let numericLevel = level as number;
      if (numericLevel > 100) { // If it's MSAL
        numericLevel -= 100;
        // Discard anything that isn't a warning or higher. MSAL absolutely SPAMS loggers.
        if (numericLevel < 3) return;
      }

      // Only attach stack traces for Warning and up
      if (numericLevel >= LogLevel.Warning) {

        const originalLimit = Error.stackTraceLimit;
        Error.stackTraceLimit = Infinity;
        const stackTrace = (new Error()).stack?.toString() || '';
        Error.stackTraceLimit = originalLimit;
  
        const full = `${message}\n\n${stackTrace}`;
        finalMessage = full.length > 8000 ? full.substring(0, 8000) : full;
      }

      const options = await this.tokenService.getOptions();
      var payload = {
          message: finalMessage,
          level,
          sourceComponent
        }
        console.log("Attempting service log: ", payload);
      // await lastValueFrom(
      //   this.http.post('/api/v1/audit', {
      //     message: truncated,
      //     level,
      //     sourceComponent
      //   }, options)
      // );
    } catch (ex: any) {
      console.log("Got this far...")
      try {
        console.log("Stringify...")
        const errMessage = typeof ex === 'string'
          ? ex
          : ex?.message || JSON.stringify(ex);
    
          console.log("Done...")
        console.warn("Failed to send log to server:", errMessage);
      } catch (inner) {
        console.warn("Log failure could not be parsed at all.");
      }
    } finally {
      //const timestamp = DateUtils.timestamp();
      // const levelName = Object.entries(LogLevel).find(([_, val]) => val === level)?.[0] ?? 'Unknown';
      // console.log(`[${timestamp}] (${levelName}) ${sourceComponent}: ${message}`);
    }

    
  }
}
