import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TokenService {
  private isInitialized = false;
  private initPromise?: Promise<void>;
  private cachedToken: string | null = null;

  constructor(private msalService: MsalService) {}

  private async lazyInit(): Promise<void> {
    if (this.isInitialized) return;
    if (this.initPromise) return this.initPromise;
  
    this.initPromise = (async () => {
      await this.msalService.instance.initialize();
      const authResponse = await this.msalService.instance.handleRedirectPromise();
  
      if (authResponse?.account) {
        this.msalService.instance.setActiveAccount(authResponse.account);
      }
  
      if (!this.msalService.instance.getActiveAccount()) {
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length > 0) {
          this.msalService.instance.setActiveAccount(accounts[0]);
        }
      }
  
      await this.ensureAuthenticated();
  
      this.isInitialized = true;
    })();
  
    return this.initPromise;
  }
  
  private async ensureAuthenticated(): Promise<boolean> {
    const account = this.msalService.instance.getActiveAccount();
    if (!account) return false;

    try {
      const result: AuthenticationResult = await firstValueFrom(
        this.msalService.acquireTokenSilent({
          account,
          scopes: ['openid', 'profile', 'User.Read']
        })
      );

      this.cachedToken = result.idToken;
      return true;
    } catch {
      return false;
    }
  }

  async getToken(accessToken = false): Promise<string | null> {
    await this.lazyInit();

    const account = this.msalService.instance.getActiveAccount();
    if (!account) return null;

    var cachedToken = this.cachedToken;
    if (cachedToken && !this.isJwtValid(cachedToken)) cachedToken = null;

    if (cachedToken) return cachedToken;

    var result: AuthenticationResult;
    try {
      result = await firstValueFrom(
        this.msalService.acquireTokenSilent({
          account,
          scopes: ['openid', 'profile', 'User.Read']
        })
      );
    } catch (err: any) {
      console.warn('Silent token acquisition failed:', err);
      return null;
    }
    

    if (!result) return null;

    cachedToken = accessToken ? result.accessToken : result.idToken;
    if (!cachedToken || !this.isJwtValid(cachedToken)) cachedToken = null;

    this.cachedToken = cachedToken;
    console.log("Valid token found.") // <-- This is never getting hit.

    return cachedToken;
  }


  private isJwtValid(token: string): boolean {
    try {
      const [, payload] = token.split('.');
      const decoded = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')));
      const now = Math.floor(Date.now() / 1000);
      return decoded.exp > now;
    } catch {
      return false;
    }
  }
  
  async getClaims(): Promise<any> {
    await this.lazyInit();

    const jwt = await this.getToken();
    if (!jwt) throw new Error('No token available for claims');

    const parts = jwt.split('.');
    if (parts.length !== 3) throw new Error('Invalid JWT format');

    const decoded = JSON.parse(atob(parts[1].replace(/-/g, '+').replace(/_/g, '/')));
    return decoded;
  }

  async getOptions(includeContentType = true, responseType: 'json' | 'text' = 'json'): Promise<any> {
    await this.lazyInit();

    const token = await this.getToken();

    const headers: any = {
      Accept: responseType === 'json' ? 'application/json' : 'text/plain'
    };

    if (includeContentType) headers['Content-Type'] = 'application/json';
    if (token) headers.Authorization = 'Bearer ' + token;

    return {
      headers,
      responseType
    } as const;
  }
}
